import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  CustomHomepageGrid,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Avatar, makeStyles } from '@material-ui/core';
import LogoWhiteBG from '../Root/LogoWhiteBG';
import GitHubIcon from '@material-ui/icons/GitHub';
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';

import React from 'react';
import { NewAnnouncementBanner } from '@backstage-community/plugin-announcements';

const ToolKitList = [
  // ...

  {
    url: 'https://github.com/flysas-tech/',
    label: 'Github',
    icon: <GitHubIcon />,
  },
  {
    url: 'https://sas-digital.app.opsgenie.com/',
    label: 'Opsgenie',
    icon: <Avatar alt="Opsgenie" src="./tools/opsgenie.png" />,
  },
  {
    url: 'https://sasdigitalhub.slack.com/',
    label: 'Slack',
    icon: <Avatar alt="Slack" src="./tools/slack_icon.png" />,
  },
  {
    url: 'https://miro.com/',
    label: 'Miro',
    icon: <Avatar alt="Miro" src="./tools/Miro_logo.png" />,
  },
  {
    url: 'https://grafana.internal.flysas.tech/#/',
    label: 'Grafana',
    icon: <Avatar alt="Grafana" src="./tools/Grafana_icon.png" />,
  },
  {
    url: 'https://uptime.com/dashboard/',
    label: 'Uptime',
    icon: <Avatar alt="Miro" src="./tools/uptime.png" />,
  },
  {
    url: 'https://sas-digital.atlassian.net/',
    label: 'Atlassian',
    icon: <Avatar alt="Atlassian" src="./tools/atlassian.png" />,
  },
  {
    url: 'https://yopass.flysas.tech/',
    label: 'Yopass',
    icon: <Avatar alt="Atlassian" src="./tools/yopass.png" />,
  },
];

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const defaultConfig = [
  {
    component: <HomePageCompanyLogo logo={<LogoWhiteBG />} />,
    x: 4, // Adjust the x value to position the component in the center
    y: 1,
    width: 2, // Adjust the width value as needed
    height: 2,
    resizable: false,
    deletable: false,
  },
  {
    component: <HomePageSearchBar />,
    x: 0,
    y: 1,
    width: 12,
    height: 2,
    deletable: true,
  },
  {
    component: <HomePageTopVisited />,
    x: 0, // Adjust the x value to position the component on the left
    y: 4,
    width: 5, // Adjust the width value to fit the whole width
    height: 5,
    deletable: true,
  },
  {
    component: <HomePageToolkit tools={ToolKitList} />,
    x: 6, // Adjust the x value to position the component on the right
    y: 5,
    width: 5, // Adjust the width value to fit the whole width
    height: 5,
    movable: true,
    resizable: true,
    deletable: true,
  },
  {
    component: <HomePageStarredEntities />,
    x: 0, // Adjust the x value to position the component on the left
    y: 8,
    width: 5, // Adjust the width value to fit the whole width
    height: 4,
    deletable: true,
  },
  {
    component: <HomePageRecentlyVisited />,
    x: 6, // Adjust the x value to position the component on the right
    y: 5,
    width: 5, // Adjust the width value to fit the whole width
    height: 4,
    deletable: true,
  },
];

export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <NewAnnouncementBanner />
          <CustomHomepageGrid config={defaultConfig}>
            // Insert the allowed widgets inside the grid
            <HomePageCompanyLogo logo={<LogoWhiteBG />} />
            <HomePageSearchBar
              InputProps={{
                classes: {
                  root: classes.searchBarInput,
                  notchedOutline: classes.searchBarOutline,
                },
              }}
              placeholder="Search"
            />
            <HomePageToolkit tools={ToolKitList} />
            <HomePageStarredEntities />
            <HomePageRecentlyVisited />
            <HomePageTopVisited />
            <HomePageRequestedReviewsCard query="is:open is:pr review-requested:@me" />
            <HomePageYourOpenPullRequestsCard query="is:open is:pr author:@me" />
          </CustomHomepageGrid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
