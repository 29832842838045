import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  defaultTypography,
} from '@backstage/theme';

const sasBlue = '#000099';
const sasFont = 'scandinavian, "Open Sans", arial, sans-serif';

export const sasTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.light,
    typography: {
      ...defaultTypography,
      fontFamily: sasFont,
    },
  }),
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: 'unset',
          backgroundColor: sasBlue,
          width: 'auto',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          'align-items': 'center',
          'font-family': sasFont,
          'font-weight': 800,
          backgroundColor: '#006FE6',
          '&:hover': {
            backgroundColor: '#0159ce',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: sasBlue,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          'font-family': sasFont,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          'font-family': sasFont,
          color: sasBlue,
        },
        h2: {
          'font-family': sasFont,
          color: sasBlue,
        },
        h3: {
          'font-family': sasFont,
          color: sasBlue,
        },
        h4: {
          'font-family': sasFont,
          color: sasBlue,
        },
        h5: {
          'font-family': sasFont,
          color: sasBlue,
        },
        subtitle1: {
          'font-family': sasFont,
          color: sasBlue,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          'background-image': 'unset',
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        buttonItem: {
          'margin-top': '15px',
        },
      },
    },
  },
});
