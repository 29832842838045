import {
  createPlugin,
  createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const defenderForCloudScoresPlugin = createPlugin({
  id: 'defender-for-cloud-scores',
  routes: {
    root: rootRouteRef,
  },
});

export const DefenderForCloudScoresPage = defenderForCloudScoresPlugin.provide(
  createRoutableExtension({
    name: 'DefenderForCloudScoresPage',
    component: () =>
      import('./components/SecurityPage').then(m => m.SecurityPage),
    mountPoint: rootRouteRef,
  }),
);

export const DefenderForCloudScoresCard = defenderForCloudScoresPlugin.provide(
  createComponentExtension({
    name: 'DefenderForCloudScoresCard',
    component: {
      lazy: () => import('./components/OverviewCard').then(m => m.OverviewCard),
    },
  }),
);
